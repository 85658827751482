@import '@/styles/breakpoints.module';

.mobile {
  display: flex;
  @include tablet {
    display: none;
  }
}

.tablet {
  display: none;
  color: var(--palette-primary-700);

  @include desktop {
    display: flex;
  }
}

.headerWrapper {
  display: grid;
  gap: 32px;
  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header {
  max-width: 653px;
}

.mobileCta {
  & > * {
    width: 100%;
  }
}

.tabOverflow {
  margin-inline-end: -12px;
  @include rwd(375) {
    margin-inline-end: 0;
  }
}

.contentWrapper {
  --info-desktop-width: 360px;
  @include desktop {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.info {
  a {
    width: 100%;

    @include tablet {
      width: initial;
    }
  }

  .btnWrapper {
    width: 100%;
    margin-bottom: 16px;

    @include tablet {
      width: initial;
      margin-bottom: 0;
    }

    @include desktop {
      margin-bottom: 16px;
    }

    a {
      width: 100%;

      @include tablet {
        width: initial;
      }
    }
  }

  //@include desktop {
  //  width: var(--info-desktop-width);
  //}
}

.uspItem {
  margin-bottom: 12px;
  @include tablet {
    margin-bottom: 16px;
  }
  @include desktop {
    margin-bottom: 24px;
  }

  .title {
    margin-bottom: 4px;
    color: var(--color-text-primary);
  }

  .subtitle {
    color: var(--color-text-secondary);
  }
}

.uspImage {
  position: relative;
  width: 100%;
  margin-top: var(--vstack-spacing-sm);
  aspect-ratio: 750/450;
  background-color: #eee; //placeholder
  border-radius: var(--image-wrapper-border-radius-s);
  box-shadow: var(--image-wrapper-shadow-s);
  overflow: hidden;

  @include tablet {
    margin-top: var(--vstack-spacing-md);
  }
  @include desktop {
    max-width: 750px;
    margin-top: 0;
    margin-inline-start: 24px;
    border-radius: var(--image-wrapper-border-radius-m);
    box-shadow: var(--image-wrapper-shadow-m);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.wrapperButtons {
  display: flex;
  flex-direction: column;
  margin-top: var(--vstack-spacing-sm);

  @include tablet {
    flex-direction: row;
    gap: 16px;
    align-items: center;
    margin-top: var(--vstack-spacing-md);
  }

  @include desktop {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    margin-top: 0;
  }
}

.uspButtons {
  display: grid;
  gap: 12px;
  text-align: center;

  @include tablet {
    display: flex;
    flex-wrap: wrap;
    text-align: start;
  }

  @include desktop {
    display: grid;
    justify-items: flex-start;
  }

  & > * {
    width: 100%;
    @include tablet {
      width: auto;
    }
  }
}

.disclaimer {
  text-align: center;
  margin-top: 16px;

  @include tablet {
    text-align: unset;
  }
}
